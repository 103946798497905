
export class Currency {
    code : string;
    rate : number;
    formatter : Intl.NumberFormat;
 
    constructor(code: string, rate: number) {
        this.code = code;
        this.rate = rate;
        this.formatter = new Intl.NumberFormat('default', {
            style: 'currency',
            currency: code,
        });
    }

    format(value : number) {
        let newValue = value;
        if (this.code !== 'USD') {
            newValue = newValue * this.rate;
        }
        return this.formatter.format(newValue);
    }
}