import { AbsoluteCenter, Box, SimpleGrid, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export default function LoadingPage() {

  const _navMenu = Number(window.localStorage.getItem("navMenu") || "0");
  const _height = Number(window.localStorage.getItem("height") || "0") - _navMenu;

  const [height, setHeight] = useState<string>(_height <= 0 ? '100vh' : ""+_height);


  useEffect(() => {

    let settings = JSON.parse(window.localStorage.getItem("settings") || '{}');

    let navHeight = 0;
    if (settings?.navHeight) {
      navHeight = Number(settings?.navHeight);
    }
    let _height= 0;
    if (settings?.height) {
      _height = Number(settings?.height);
    }

    setHeight(_height - navHeight === 0 ? "" : (_height - navHeight).toString());

  }, []);

    return (
      <SimpleGrid
        columns={1}
        spacing='8'
        p='10'
        textAlign='center'
        rounded='lg'
        color='gray.400'
      >
        <Box position='relative' h={height || '100vh'}>
          
          <AbsoluteCenter p='100'  axis='both'>
            <Spinner size='xl'
              emptyColor='gray.200'
              color='green.500'
            />

          </AbsoluteCenter>
        </Box>
      </SimpleGrid>
    )
}