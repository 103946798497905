import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Box, Card, CardBody, CardHeader, Flex, HStack, Heading, SimpleGrid, Skeleton, Stack, Text, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Currency } from '../lib/Currency';
import DefaultUtils from '../lib/DefaultUtils';
import LineChart from '../lib/LineChart';
import ModalExtends from '../lib/ModalExtends';
import NavMenu from './NavMenu';

//http://localhost:3000/view?msg=eyJpZHMiOlsiYWNjb3VudHMvcHViLTAwMzE2MjM3Mjc0NjgzNzEiXSwiYXV0aF9oZWFkZXIiOnsiQXV0aG9yaXphdGlvbiI6IkJlYXJlciB5YTI5LmEwQWZCX2J5QkxFRk8yVzRLVVh1QmdJYXA1WDVtVmJKd3VJT2FPXzctY1RNRWk0cG9OejdzVExjMWVEc3NhQlVLa3hzSjNhYl91c05EQlpheXpoMjlOZEhOVEIxclJQYWktY2NPVWZnOUNyZDhyODBOU1ZONnhkaS1PVDJpQkp4eThnSWtxc2pFakVEYnFRLWNCTE05TTJESXpqc2YwMkRieEFqaWlhQ2dZS0FjOFNBUkFTRlFHT2NObkMyTUE1b1B4THBjREdtNEo2Q2hTNGNnMDE3MSIsIlgtR29vZy1BdXRoVXNlciI6IjAifSwid2lkdGgiOjM5My4wLCJoZWlnaHQiOjg1Mi4wLCJsYW5ndWFnZUNvZGUiOiJlbiIsImNvdW50cnlDb2RlIjoiVVMiLCJ1c2VyIjp7ImRpc3BsYXlOYW1lIjoiU2V5a290YSBFZCIsImVtYWlsIjoidmljaGFyYXNAZ21haWwuY29tIiwiaWQiOiIxMTQ4NzA2NjIxNTMyNjAxMTMxMTMiLCJwaG90b1VybCI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0xOMXFRSXBsakxRODhqbDUwMEh0ampCWkQtemVpVV9CNTNNVENJOGdhZjBrOD1zMTMzNyJ9LCJlcnJNc2ciOiIifQ==

export default function ViewPage() {
  const queryParameters = new URLSearchParams(window.location.search)
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const [dateToData, setDateToData] = useState<Record<string,any>>();
  const [data, setData] = useState<any[]>();
  const [todayData, setTodayData] = useState<any[]>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [keyToAvg, setKeyToAvg] = useState<Record<string,string>>();
  const [param, setParam] = useState<any>();
  const [lastFetch, setLastFetch] = useState<string>();
  const [msg, setMsg] = useState<string>();
  const toast = useToast();

  const [currencyClass, setCurrencyClass] = useState<Currency>(new Currency('USD', 0));

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [
    {
      label: 'Last 7 days',
      data: [65, 59, 80, 81, 56, 55, 40],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Last 14 days',
      data: [65, 59, 80, 81, 56, 55, 40],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }]
  });

  const adsense_host = "https://adsense.googleapis.com";

  //https://developers.google.com/adsense/management/reference/rest/v2/Metric
  const metrics = [
    "PAGE_VIEWS",
    "AD_REQUESTS",
    "MATCHED_AD_REQUESTS",
    "TOTAL_IMPRESSIONS",
    "IMPRESSIONS",
    "INDIVIDUAL_AD_IMPRESSIONS",
    "CLICKS",
    "AD_REQUESTS_COVERAGE",
    "PAGE_VIEWS_CTR",
    "AD_REQUESTS_CTR",
    "MATCHED_AD_REQUESTS_CTR",
    "IMPRESSIONS_CTR",
    "INDIVIDUAL_AD_IMPRESSIONS_CTR",
    "ACTIVE_VIEW_MEASURABILITY",
    "ACTIVE_VIEW_VIEWABILITY",
    "ACTIVE_VIEW_TIME",
    "ESTIMATED_EARNINGS",
    "PAGE_VIEWS_RPM",
    "AD_REQUESTS_RPM",
    "MATCHED_AD_REQUESTS_RPM",
    "IMPRESSIONS_RPM",
    "INDIVIDUAL_AD_IMPRESSIONS_RPM",
    "COST_PER_CLICK",
    "ADS_PER_IMPRESSION",
    "TOTAL_EARNINGS",
    "WEBSEARCH_RESULT_PAGES",
    "FUNNEL_REQUESTS",
    "FUNNEL_IMPRESSIONS",
    "FUNNEL_CLICKS",
    "FUNNEL_RPM"
  ];

  const fetchData = async (ids : string[], header : Record<string, string>, metric : string[], today : boolean = false, customUrl : string = '') => {
    let _dateToData : Record<string,any> = {};
    let nameToAvg   : Record<string,string> = {};

    if (customUrl && !ids) {
      ids = ['1'];
    }

    for (let row of ids) {
      let metricParam = "";
      for (let row2 of metric) {
        metricParam += `&metrics=${row2}`;
      }

      let url = '';
      if (today) {
        url = `${adsense_host}/v2/` + row + `/reports:generate?dateRange=TODAY&dimensions=DATE&${metricParam}&alt=json`;
      }
      else {
        url = `${adsense_host}/v2/` + row + `/reports:generate?dateRange=LAST_30_DAYS&dimensions=DATE&${metricParam}&alt=json`;
      }

      if (customUrl) {
        url = customUrl;
      }

      let res = await fetch(url, {
        method: 'GET',
        headers : header,
      });
      
      let json = await res.json();

      if (json?.error) {
        json["requestUrl"] = url;
        DefaultUtils.sendTelegram(JSON.stringify(json));
        DefaultUtils.doNative('google-signout');
        return;
      }

      let nameToIdx : Record<string,number> = {};
      let idxToName : Record<number,string> = {};
      for (let i=0; i<json.headers.length; i++) {
        let row = json.headers[i];
        if (row.name === "DATE") {
          nameToIdx["DATE"] = i;
        }
        else {
          nameToIdx[row.name] = i;
          idxToName[i] = row.name;
        }
      }

      let fetchAvgKey : string[] = [];

      for (let i=0; i<json.averages.cells.length; i++) {
        let row = json.averages.cells[i];

        if (Object.keys(row).length <= 0) {
          continue;
        }

        let name = idxToName[i];
        if (row.name === "DATE") {
          continue;
        }

        if (Number(row.value) === 0) {
          fetchAvgKey.push(idxToName[5]);
          continue;
        }

        nameToAvg[name] = row.value;
      }


      let tempKeyToAvg : Record<string,any> = {};

      if (customUrl) {
        let startdate = moment();
        for (let row of json.rows.reverse()) {
          for (let i=0; i<row.cells.length; i++) {
            if (nameToIdx["DATE"] === i) {
              row.cells[i].value = startdate.subtract(1, "days").format("YYYY-MM-DD");
            }
          }
        }
        json.rows.reverse();
      }

      for (let row of json.rows) {
        let date = "";
        for (let i=0; i<row.cells.length; i++) {
          let row2 = row.cells[i];
          if (nameToIdx["DATE"] === i) {
            date = row2.value;
          }
          else {
            let data : Record<string,any> = {};
            if (DefaultUtils.keyExists(_dateToData, date)) {
              data = _dateToData[date]
            }
            let metric = idxToName[i];
            if (fetchAvgKey.length > 0 && fetchAvgKey.indexOf(metric) > -1) {
              let temp : number[] = [];
              if (DefaultUtils.keyExists(tempKeyToAvg, metric) ) {
                temp = tempKeyToAvg[metric];
              }
              temp.push(Number(row2.value));
              tempKeyToAvg = {[metric] : temp};
            }
            let temp = 0;
            if (DefaultUtils.keyExists(data, `${metric}`)) {
              temp = Number(data[`${metric}`])
            }
            temp += Number(row2.value);
            data[`${metric}`] = ""+temp;
            _dateToData[date] = data;
          }
        }
      }
      for (let key of Object.keys(tempKeyToAvg)) {
        let sum = 0;
        for (let row of tempKeyToAvg[key]) {
          sum += row;
        }
        
        if (!DefaultUtils.keyExists(nameToAvg, key)) {
          let avg = (sum / tempKeyToAvg[key].length).toFixed(3);
          nameToAvg[key] = avg;
        }
      }
    }
    let arr = [];
    for (let dateKey of Object.keys(_dateToData)) {
      let temp_obj : Record<string,string> = {'DATE' : dateKey};
      for (let row2 of Object.keys(_dateToData[dateKey])) {
        temp_obj[row2] = _dateToData[dateKey][row2];
      }
      arr.push(temp_obj);
    }
    arr = arr.reverse();
    if (today) {
      setTodayData(arr);
      setLoading(false);
    }
    else {
      console.log(`_dateToData ${JSON.stringify(_dateToData)}`)
      setData(arr);
      setKeyToAvg(nameToAvg);
      setDateToData(_dateToData);
    }
  }

  useEffect(() => {

    let currency = window.localStorage.getItem("currency");

    if (currency && currency !== 'USD') {
      fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json`)
      .then((res) => {
          res.json().then((json) => {
            let rate = 0;
            if (currency && DefaultUtils.keyExists(json, 'usd') 
            && DefaultUtils.keyExists(json['usd'], currency?.toLowerCase())) {
              rate = json['usd'][currency?.toLowerCase()];
            }
            else {
              rate = 0;
            }
            setCurrencyClass(new Currency(currency || '', rate));
          });
      });
      
    }

    const temp = async (callBack : () => void = () => {}) => {
      let _data = DefaultUtils.decodeBase64(queryParameters);
      const user = _data?.user as any;
      // setMsg(JSON.stringify(_data));
      if (user) {
        window.localStorage.setItem("user", JSON.stringify(user));
      }
      
      let beforeStr = window.localStorage.getItem("settings") || '{}';
      let settings = JSON.parse(beforeStr);
      let navHeight = settings?.navHeight || _data?.navHeight;
      let height = settings?.height || _data?.height;
      let languageCode = settings?.languageCode || _data?.languageCode;
      let ids = settings?.ids || _data?.ids as string[];
      let header = settings?.header || _data?.auth_header as Record<string, string>;
      let clientHeight = document.getElementById("navMenu")?.clientHeight;

      moment.locale(languageCode);

      if (!navHeight || (navHeight && clientHeight && Number(navHeight) !== clientHeight)) {
        if (clientHeight) {
          navHeight = ""+clientHeight;
        }
      }

      if (!height || (height && height !== _data?.height)) {
        if (_data?.height) {
          height = _data?.height;
        }
      }
      settings = {...settings, ...{
        navHeight : navHeight,
        height : height,
        languageCode : languageCode,
        ids : ids,
        header : header,
      }}

      let after = JSON.stringify(settings);
      if (beforeStr !== after) {
        window.localStorage.setItem("settings", after);
      }

      setParam(_data);
      let isDump = window.location.host.includes("adjeju.calcflix.com") ? false : true;
      let test = window.localStorage.getItem("test");
      if (test && test === "true") {
        isDump = true;
      }
      // isDump = false;

      // await DefaultUtils.delay(5000);

      try {
        if (isDump) {
          await fetchData(ids, header, ["PAGE_VIEWS", "AD_REQUESTS", "CLICKS", "TOTAL_IMPRESSIONS", "PAGE_VIEWS_RPM", "ESTIMATED_EARNINGS"], false, '/json/last30.json');
          await fetchData(ids, header, ["PAGE_VIEWS", "AD_REQUESTS", "CLICKS", "TOTAL_IMPRESSIONS", "PAGE_VIEWS_RPM", "ESTIMATED_EARNINGS"], true, '/json/today.json');
        }
        else {
          await fetchData(ids, header, ["PAGE_VIEWS", "AD_REQUESTS", "CLICKS", "TOTAL_IMPRESSIONS", "PAGE_VIEWS_RPM", "ESTIMATED_EARNINGS"]);
          await fetchData(ids, header, ["PAGE_VIEWS", "AD_REQUESTS", "CLICKS", "TOTAL_IMPRESSIONS", "PAGE_VIEWS_RPM", "ESTIMATED_EARNINGS"], true);
        }
      }
      catch (error) {
        // DefaultUtils.reportError({ message: DefaultUtils.getErrorMessage(error) });
        showBoundary(error);
      }

      setLastFetch(moment().format());
      DefaultUtils.doNative('isFetch');

      console.log(`dateToData ${JSON.stringify(dateToData)} ${moment().format()} ${moment(moment().format()).fromNow()}`);
      if (callBack) {
        callBack();
      }
    };
    temp();

    // DefaultUtils.addToWebListener("refresh", () => {
    //     let minutes = 0;
    //     if (lastFetch) {
    //       let duration = moment.duration(moment().diff(moment(lastFetch)));
    //       minutes = duration.asMinutes();
    //     }

    //     if (minutes > 10 || !lastFetch) {
    //       temp(() => {
    //         // setMsg("refresh");
    //         toast({
    //           title: 'Refresh',
    //           description: `Refresh!`,
    //           status: 'success',
    //           duration: 9000,
    //           isClosable: true,
    //         })
    //       });
    //     }
    // });

  }, []);
  // borderColor: 'rgb(255, 99, 132)',
  // backgroundColor: 'rgba(255, 99, 132, 0.5)',
  useEffect(() => {
    if (data && data.length >= 14) {

      let date = moment();
      let tempArr = [];

      for (let i=1; i<=7; i++) {
        tempArr.push(date.subtract(1, "days").fromNow());
      }

      setChartData({
        ...chartData,
        labels: tempArr,
        datasets : [{
          label: 'Last 7 days',
          data: toNumberArr(data.slice(0,7), 'ESTIMATED_EARNINGS'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Last 14 days',
          data: toNumberArr(data.slice(7,14), 'ESTIMATED_EARNINGS'),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }]
      });
    }

  }, [data]);

  const todayKeyToData = (key : string) => todayData && todayData[0][key];

  const avgToMinutes = (key : string) => {
    if (keyToAvg) {
      let pastMinutes = moment().hours() * 60 + moment().minute();
      let minutesRate = Number(keyToAvg[key]) / 1440;

      return avgPer(Number(todayKeyToData(key)), Number(minutesRate * pastMinutes));
    }
    
    return "";
  }

  const getAvg = (key : string, row : any) => {
    return keyToAvg && DefaultUtils.keyExists(keyToAvg, key) && avgPer(Number(row[key]), Number(keyToAvg[key]));
  }

  const getTodayAvg = (key : string) => {
    return todayData && keyToAvg && DefaultUtils.keyExists(keyToAvg, key) && avgPer(Number(todayData[0][key]), Number(keyToAvg[key]));
  }

  const toNumberArr = (data : any, key : string) => {
    let arr : number[] = [];
    for (let row of data) {
      arr.push(Number(row[key]));
    }

    return arr;
  }

  const subtract = (minus : number) => moment().subtract(minus, "days").format("YYYY-MM-DD")

  const avgPer = DefaultUtils.avgPer


    return (
      <>
      <NavMenu />
      {isLoading ? 
<SimpleGrid
columns={1}
spacing='8'
p='5'
textAlign='center'
rounded='lg'
>
  <Stack>
    <Skeleton height='50' />
    <Skeleton height='50' />
    <Skeleton height='50' />
    <Skeleton height='50' />
    <Skeleton height='50' />
    <Skeleton height='50' />
  </Stack> 
</SimpleGrid>
  :
        <>
          {msg ? <ModalExtends text={msg} /> : ""}
          <SimpleGrid
            columns={1}
            spacing='8'
            p='5'
            textAlign='center'
            rounded='lg'
          >
                <Card size={"sm"}>
                  <CardHeader>
                    <Heading size='md'>
                    <Flex>
                      <Box flex="1">
                          <Box>
                            <Text fontSize='md'>Today</Text>
                            <Text fontSize='xl'>
                              {currencyClass.format(todayKeyToData('ESTIMATED_EARNINGS'))}
                            </Text>
                            <Text fontSize='sm' color={Number(avgToMinutes('ESTIMATED_EARNINGS')) > 0 ? "green" : "red"}>
                              {avgToMinutes('ESTIMATED_EARNINGS') +"%"}
                              {Number(avgToMinutes('ESTIMATED_EARNINGS')) > 0 ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                            </Text>
                          </Box>
                      </Box>
                      <Box flex='1'>
                          <Box>
                            <Text fontSize='md'>Yesterday</Text>
                            {dateToData && DefaultUtils.keyExists(dateToData, subtract(1)) ?
                            <>
                              <Text fontSize='xl'>{currencyClass.format(dateToData[subtract(1)]['ESTIMATED_EARNINGS'])}</Text>
                              {/* <Text fontSize='xl'>{currencyClass.format(dateToData[subtract(8)]['ESTIMATED_EARNINGS'])}</Text> */}
                              {DefaultUtils.keyExists(dateToData, subtract(8)) ?
                              <Text fontSize='sm' color={DefaultUtils.avgPer(dateToData[subtract(1)]['ESTIMATED_EARNINGS'], dateToData[subtract(8)]['ESTIMATED_EARNINGS']) > 0 ? "green" : "red"}>
                                {avgPer(dateToData[subtract(1)]['ESTIMATED_EARNINGS'], dateToData[subtract(8)]['ESTIMATED_EARNINGS']) + "%"}
                                {avgPer(dateToData[subtract(1)]['ESTIMATED_EARNINGS'], dateToData[subtract(8)]['ESTIMATED_EARNINGS']) > 0 ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                              </Text>
                              : ""}
                            </>
                            : ""}
                          </Box>
                      </Box>
                    </Flex>
                    <Flex pt={10}>
                      <Box flex="1">
                          <Box>
                            <Text fontSize='md'>last 7 days</Text>
                            <Text fontSize='xl'>{data && data.length >= 7 ? currencyClass.format(DefaultUtils.sum(data.slice(0,7), 'ESTIMATED_EARNINGS')) : ""}</Text>
                            {/* <Text fontSize='xl'>{data && data.length >= 7 ? currencyClass.format(DefaultUtils.sum(data.slice(7,14), 'ESTIMATED_EARNINGS')) : ""}</Text> */}
                            
                            {data && data.length >= 14 ?
                            <>
                              {DefaultUtils.keyExists(dateToData, subtract(8)) ?
                              <Text fontSize='sm' color={Number(avgPer(DefaultUtils.sum(data.slice(0,7), 'ESTIMATED_EARNINGS'), DefaultUtils.sum(data.slice(7,14), 'ESTIMATED_EARNINGS'))) > 0 ? "green" : "red"}>
                                {avgPer(DefaultUtils.sum(data.slice(0,7), 'ESTIMATED_EARNINGS'), DefaultUtils.sum(data.slice(7,14), 'ESTIMATED_EARNINGS')) + "%"}
                                {avgPer(DefaultUtils.sum(data.slice(0,7), 'ESTIMATED_EARNINGS'), DefaultUtils.sum(data.slice(7,14), 'ESTIMATED_EARNINGS')) > 0 ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                              </Text>
                              : ""}
                            </>
                            : ""}
                          </Box>
                      </Box>
                      <Box  flex='1'>
                          <Box>
                            <Text fontSize='md'>last 30 days</Text>
                            <Text fontSize='xl'>{data ? currencyClass.format(DefaultUtils.sum(data.slice(0,30), 'ESTIMATED_EARNINGS')) : ""}</Text>
                          </Box>
                      </Box>
                    </Flex>
                    </Heading>
                  </CardHeader>
                  <CardBody>
                  </CardBody>
                </Card>
          </SimpleGrid>

          {chartData && labels !== chartData.labels ?
          <SimpleGrid
            columns={1}
            spacing='8'
            p='5'
            paddingLeft='2'
            textAlign='center'
            rounded='lg'
          >
        <LineChart  
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
              },
              title: {
                display: true,
                text: 'Last 7 days vs Last 14 days',
              },
            },
          }}
            chartData={chartData}
          />
          </SimpleGrid>

          : ""}
          <Stack spacing='4'>
            {data ? data?.map((row) => {
              return (
                <Card key={`${row.DATE}-sm`} size={"sm"}>
                  <CardHeader>
                    <Heading size='md'>
                    <Flex>
                      <Box flex="1">
                        <HStack spacing={0.5}>
                          <Box>
                            <Text fontSize='2xl'>{currencyClass.format(Number(row.ESTIMATED_EARNINGS))}</Text>
                          </Box>
                          <Box color={Number(getAvg('ESTIMATED_EARNINGS', row)) > 0 ?  "green" : "red"}>
                            <Text fontSize='sm'>{Number(getAvg('ESTIMATED_EARNINGS', row)) > 0 ? <ArrowUpIcon /> : <ArrowDownIcon /> }</Text>
                          </Box>
                          <Box color={Number(getAvg('ESTIMATED_EARNINGS', row)) > 0 ?  "green" : "red"}>
                            <Text fontSize='sm'>{Number(getAvg('ESTIMATED_EARNINGS', row)) !== 0 ?  Number(getAvg('ESTIMATED_EARNINGS', row)) +"%"  : ""}</Text>
                          </Box>
                        </HStack>
                      </Box>
                      <Box textAlign={'right'} flex='1'>
                        <Text fontSize='sm'>{moment(row.DATE, "YYYY-MM-DD").fromNow()}</Text>
                      </Box>
                    </Flex>
                    </Heading>
                  </CardHeader>
                  <CardBody>
                    <Flex>
                      <Box flex="1">
                        <Text fontSize='sm'>Requests : {Number(row.AD_REQUESTS).toLocaleString()}</Text>
                        <Text fontSize='sm'>Page views : {Number(row.PAGE_VIEWS).toLocaleString()}</Text>
                        <Text fontSize='sm'>RPM : {Number(row.PAGE_VIEWS_RPM).toLocaleString()}</Text>
                      </Box>
                      <Box textAlign={'right'} flex='1'>
                        <Text fontSize='sm'>Impressions : {Number(row.TOTAL_IMPRESSIONS).toLocaleString()}</Text>
                        <Text fontSize='sm'>Clicks : {Number(row.CLICKS).toLocaleString()}</Text>
                      </Box>
                    </Flex>
                  </CardBody>
                </Card>
              )
            }) : ""}

          </Stack>
        </>
      }
      </>
    )
}