import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ConfigPage from './pages/ConfigPage';
import LoadingPage from './pages/LoadingPage';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import ViewPage from './pages/ViewPage';

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

const theme = extendTheme({ colors })
export default function App() {
  return (
    <HelmetProvider>
      <ChakraProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/loading" element={<LoadingPage />} />
              <Route path="/view" element={<ViewPage />} />
              <Route path="/config" element={<ConfigPage />} />
            </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </HelmetProvider>
  );
}