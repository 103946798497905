import { AbsoluteCenter, Box, Button, SimpleGrid, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultUtils from '../lib/DefaultUtils';
import { Helmet } from 'react-helmet-async';

export default function LoginPage() {
  const queryParameters = new URLSearchParams(window.location.search)
  const msg = queryParameters.get("msg")
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errMsg, setErrMsg] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAuthorized, setAuthorized] = useState<number>(-1);
  const toast = useToast();

  useEffect(() => {

    (window as any).handleCredentialResponse = (googleUser : any) => {
      console.log(`googleUser ${JSON.stringify(googleUser)}`)
    }

    // document.addEventListener("flutterInAppWebViewPlatformReady", function (event : any) {
    //   console.log(`flutterInAppWebViewPlatformReady2`);
    //   const args = [1, true, ['bar', 5], {foo: 'baz'}];
    //   (window as any).flutter_inappwebview.callHandler('handlerFooWithArgs', ...args);
    // });

    // (window as any).addEventListener("flutterInAppWebViewPlatformReady", (event : any) => {
    //   console.log(`flutterInAppWebViewPlatformReady`);
    //   const args = [1, true, ['bar', 5], {foo: 'baz'}];
    //   (window as any).flutter_inappwebview.callHandler('handlerFooWithArgs', ...args);


    //   let handler = (window as any).flutter_inappwebview?.callHandler || (window as any).flutter_inappwebview?._callHandler;
    //   handler('handlerFoo')
    //     .then((result : any) => {
    //       // print to the console the data coming
    //       // from the Flutter side.
    //       console.log(`flutterInAppWebViewPlatformReady handlerFoo ${JSON.stringify(result)}`);
          
    //       handler('handlerFooWithArgs', 1, true, ['bar', 5], {foo: 'baz'}, result);
    //   });
    // });
    

    // variable that will represents the port used to communicate with the Dart side
    var port;
    // listen for messages
    window.addEventListener('message', function(event) {
      console.log(`message`);
        if (event.data == 'capturePort') {
            // capture port2 coming from the Dart side
            if (event.ports[0] != null) {
                // the port is ready for communication,
                // so you can use port.postMessage(message); wherever you want
                port = event.ports[0];
                // To listen to messages coming from the Dart side, set the onmessage event listener
                port.onmessage = function (event) {
                    // event.data contains the message data coming from the Dart side 
                    console.log(event.data);
                };
            }
        }
    }, false);

    const temp = async () => {

      DefaultUtils.addToWebListener("init", async (_data : any) => {

        // toast({
        //   title: 'Refresh',
        //   description: `${JSON.stringify(_data)}`,
        //   status: 'success',
        //   duration: 9000,
        //   isClosable: true,
        // })

        let beforeStr = window.localStorage.getItem("settings") || '{}';
        let settings = JSON.parse(beforeStr);
        let navHeight = _data?.navHeight || settings?.navHeight;
        let height = _data?.height || settings?.height;
        let languageCode = _data?.languageCode || settings?.languageCode;
        let isAuthorized = _data?.isAuthorized || false;
        setAuthorized(isAuthorized ? 1 : 0);
        
        settings = {...settings, ...{
          navHeight : navHeight,
          height : height,
          languageCode : languageCode,
        }}
  
        let after = JSON.stringify(settings);
        if (beforeStr !== after) {
          window.localStorage.setItem("settings", after);
        }
      });
      if (errMsg) {
        onOpen();
      }
      setErrMsg(errMsg);
    };
    temp();
  }, []);


    return (
      <>
        <SimpleGrid
        columns={1}
        spacing='8'
        p='10'
        textAlign='center'
        rounded='lg'
        color='gray.400'
      >
        <Box position='relative' h='100vh'>
          <AbsoluteCenter p='100'  axis='both'>
            {isLoading && <Spinner size='xl' />}
            <Box p={5}>
            <Helmet>
            <script src="https://accounts.google.com/gsi/client" async={true} defer={true}></script>
            </Helmet>
            {/* <div id="g_id_onload"
                data-client_id="729906572217-gkre1pm9sc91ojtsbdet8ulb0fjp28qn.apps.googleusercontent.com"
                data-callback="handleCredentialResponse">
            </div> */}

<div id="g_id_onload"
     data-client_id="729906572217-gkre1pm9sc91ojtsbdet8ulb0fjp28qn.apps.googleusercontent.com"
     data-context="signin"
     data-ux_mode="redirect"
     data-login_uri="http://localhost:3000/callback"
     data-itp_support="true">
</div>

<div className="g_id_signin"
     data-type="standard"
     data-shape="pill"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div>
            {/* <div id="g_id_onload"
                data-client_id="729906572217-gkre1pm9sc91ojtsbdet8ulb0fjp28qn.apps.googleusercontent.com"
                data-ux_mode="redirect"
                data-login_uri="http://localhost:5000">
            </div> */}
            {/* <div className="g_id_signin" data-type="standard"></div> */}
              <Button colorScheme='teal' size='lg' onClick={e => {
                setLoading(true);
                let test = window.localStorage.getItem("test");
                if (test) {
                  window.localStorage.removeItem("test");
                }
                if (isAuthorized === 0) {
                  // window.open('https://accounts.google.com/gsi/select?client_id=729906572217-gkre1pm9sc91ojtsbdet8ulb0fjp28qn.apps.googleusercontent.com&ux_mode=popup&ui_mode=card&as=zG%2FJWPLO2uxBswHzH7M%2Brw&channel_id=a3f11f4f2117584d4b2a7145d350e2e285f4e847c25b3f887a180f211f185b62&origin=http%3A%2F%2Flocalhost%3A3000', '_blank')
                  DefaultUtils.doNative('request-permission');
                }
                else {
                  // window.open('https://accounts.google.com/gsi/select?client_id=729906572217-gkre1pm9sc91ojtsbdet8ulb0fjp28qn.apps.googleusercontent.com&ux_mode=popup&ui_mode=card&as=zG%2FJWPLO2uxBswHzH7M%2Brw&channel_id=a3f11f4f2117584d4b2a7145d350e2e285f4e847c25b3f887a180f211f185b62&origin=http%3A%2F%2Flocalhost%3A3000', '_blank')
                  DefaultUtils.doNative('google-signin');
                }
              }}>
                {isAuthorized === 0 ? "request Permission" : "Sign In for Google"}
              </Button>

              
            </Box>
            <Box>
              <Button colorScheme='teal' size='lg' onClick={e => {
                setLoading(true);
                window.localStorage.setItem("test", "true");
                navigate("/view")
              }}>
                Test Login
              </Button>
            </Box>

          </AbsoluteCenter>
        </Box>
      </SimpleGrid>
      </>
    )
}