import {
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export interface LineChartArgs {
    chartData : ChartData<'line'>
    options : ChartOptions<'line'>
}
export default function LineChart(args : LineChartArgs) {
    return (
        <>
            <Line options={args.options} data={args.chartData} />
        </>
    )
}