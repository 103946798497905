import React, { ErrorInfo, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from "react-error-boundary";
import DefaultUtils from './lib/DefaultUtils';
import { SimpleGrid } from '@chakra-ui/react';

function Fallback(error : any, resetErrorBoundary : (...args: any[]) => void) {
  return (
    <SimpleGrid
    columns={1}
    spacing='8'
    p='5'
    textAlign='center'
    rounded='lg'
  >
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    </SimpleGrid>
  );
}

const errFn = (error: Error, info: ErrorInfo) => {
  try {
    let err = error?.message || '';

    if (err) {
      DefaultUtils.sendTelegram(err);
    }
  }
  catch (e) {

  }

};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Fallback} onError={errFn}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
