import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Center,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultUtils from '../lib/DefaultUtils'

export default function NavMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState<any>();
  const navigate = useNavigate();



  useEffect(() => {
    let user = JSON.parse(window.localStorage.getItem("user") || "{}")
    setUser(user);
  }, []);

  return (
    <>

<Box minH={isOpen ? '100vh' : ''}>
  <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
  <Drawer
    isOpen={isOpen}
    placement="left"
    onClose={onClose}
    returnFocusOnClose={false}
    onOverlayClick={onClose}
    size="full">
    <DrawerContent>
      <SidebarContent onClose={onClose} />
    </DrawerContent>
  </Drawer>

<Grid
  templateAreas={`"header header"`}
  h={40}
>
  <GridItem area={'header'}>
    <Flex id="navMenu" paddingTop={12} bg={useColorModeValue('gray.100', 'gray.900')} px={4} zIndex={999}
        align="center"
        justify="center"
        width={"100%"}
        position="fixed"
    >
      <Box flex={1} p='4'>
        <IconButton
            size={'md'}
            icon={<HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={onOpen}
        />
      </Box>
      <Spacer flex={1} p='6' justifyContent="center">
        <Center>AdJeju</Center>
      </Spacer>
      <Box flex={1}>
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}>
            <Avatar
              size={'sm'}
              src={user?.photoUrl}
            />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={e => {

              let test = window.localStorage.getItem("test");
              if (test && test === "true") {
                navigate("/login")
              }
              else {
                DefaultUtils.doNative('google-signout');
              }
              
            }}>Sign out</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  </GridItem>
</Grid>
</Box>
    </>
  )
}

interface LinkItemProps {
  name: string
  icon: string
  href: string
}

interface NavItemProps extends FlexProps {
  icon: string
  children: React.ReactNode
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Dashboard', icon: '', href : '/view' },
  { name: 'Settings', icon: '', href : '/config' },
]
const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const navigate = useNavigate();

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" paddingTop={12} marginBottom={5} alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Menu
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={e => {
          if ('/view' === link.href) {
            DefaultUtils.doNative('goto', {url : link.href});
          }
          else {
            link.href && navigate(link.href);
          }
        }}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            // as={icon || ''}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}