import { Base64 } from 'js-base64';
import { v4 as uuidv4 } from 'uuid';

export type NativeTypes = 'err' | 'google-signin' | 'google-signout' | 'goto' | 'request-permission' | 'isFetch'

export default class DefaultUtils {
    static toApp (type : string, callback : () => void = () => {}) {

        let uuid = uuidv4();
        let data = {
            type : type,
            callBack : "",
        };
        let temp = () => {};
        
        if (callback !== temp) {
            (window as any)[uuid] = () => {
                callback();
                
                setTimeout(() => {
                    try {
                        delete (window as any)[uuid];
                    }
                    catch (e) {
        
                    }
                }, 500);
            };
            data["callBack"] = uuid;
        }

        (window as any).flutter_inappwebview.callHandler('toApp', data);
        // (window as any).toApp.postMessage(JSON.stringify(data));
    }

    static keyExists(obj : any, key : string) : boolean {
        return obj[key] !== undefined;
    }

    static currency(value : number) : string {
        const formatter = new Intl.NumberFormat('default', {
            style: 'currency',
            currency: 'USD',
        });
        
        return formatter.format(value);
    }

    static avgPer(value : number, avg : number) : number {
        return Number(((Number(value) / Number(avg) -1) * 100).toFixed(2))
    }

    static decodeBase64(queryParameters : URLSearchParams) : any {
        const msg = queryParameters.get("msg");
        if (msg) {
            let res = Base64.decode(msg);
            let data = JSON.parse(res);
            console.log(`data ${JSON.stringify(data)}`);
            return data;
        }
        return null;
    }

    static encodeBase64(value : string) : string {
        return Base64.encode(value)
    }

    static addToWebListener(type : string, callBack : (data : any) => void) {
        (window as any).toWeb = (msg : string) => {
            let res = Base64.decode(msg);
            let data = JSON.parse(res);
            let _type = data?.type as string;
            if (type === _type) {
                callBack && callBack(data);
            }
        };
    }

    static sum(data : any[], key : string) : number {
        let _sum = 0;
        for (let row of data) {
            _sum += Number(row[key]);
        }

        return _sum;
    }

    static sendTelegram(value : string) : void {
        DefaultUtils.doNative('err', {"msg" : value});
    }

    static doNative(native : NativeTypes, data : any = {}) : void {
        (window as any).flutter_inappwebview.callHandler('toApp', {
            "type" : native.toString(),
            ...data
        });

        // (window as any).toApp.postMessage(JSON.stringify({
        //     "type" : native.toString(),
        //     ...data
        // }));
    }

    static getErrorMessage(error: unknown) {
        if (error instanceof Error) return error?.message;
        return String(error);
    }
    static reportError = ({ message }: { message: string }) => {
        DefaultUtils.sendTelegram(message);
    };

    static async delay(delayInms : number) : Promise<void> {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }
}